import { httpPost } from "../../../../../../../Utils/apis/apis";
export const invoice = {
    INBOUND_INVOICE : "inboundinvoice"
}

export const getChart = async (chartRequest) => {
    let url = "/ChartData/getchartdata";
    let requestPayload = chartRequest;
    const result = await httpPost(url, requestPayload);
    return result;
}

 export const ExecuteGremblinSP = async (content) => {
    let url = `/GenericGremlin/generic/${
      content && content.genericGremlinSP ? content.genericGremlinSP : ""
    }`;
    let requestPayload = {
      "@id": content["id"],
    };
    const result = await httpPost(url, requestPayload);
    return result;
  };