/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from "prop-types";

const DolmaICReport = ({ ICReportData }) => {
    let mattersData = ICReportData.selectedIRCRecord;
    const attendees = transformData(ICReportData?.attendees?.series);
    const clientLogoURL = ICReportData.clientLogoURL;
    //const owner = ICReportData.owner;
    let Peoples = {
        "Byron Askin": mattersData?.["Byron Askin"] || "",
        "Tim Gocher": mattersData?.["Tim Gocher"] || "",
        "Bidhya Sigdel": mattersData?.["Bidhya Sigdel"] || "",
        "Shabda Gyawali": mattersData?.["Shabda Gyawali"] || "",
        "Kundan Saran": mattersData?.["Kundan Saran"] || "",
    };


    const matters = [
        {
            title: "Determine Quorum",
            discussion: mattersData?.["Determine Quorum"] || ""
        },
        {
            title: "Investment Structure",
            discussion: mattersData?.["Investment Structure"] || ""
        },
        {
            title: "Brief Description of the Company",
            discussion: mattersData?.["Brief Description of the Company"] || ""
        },
        {
            title: "Industry Overview and Competition",
            discussion: mattersData?.["Industry Overview and Competiton"] || ""
        },
        {
            title: "Business Plan",
            discussion: mattersData?.["Business Plan"] || ""
        },
        {
            title: "Financials and Valuation",
            discussion: mattersData?.["Financials and Valuation"] || ""
        },
        {
            title: "E&S Update",
            discussion: mattersData?.["E&S Update"] || ""
        },
        {
            title: "IC Discussion",
            discussion: mattersData?.["IC Discussion"] || ""
        },
        {
            title: "Voting",
            discussion: generateHtmlTable(Peoples)
        },
        {
            title: "Final Remarks",
            discussion: mattersData?.["Final Remarks"] || ""
        },
        {
            title: "Next Steps",
            discussion: mattersData?.["Next Steps"] || ""
        },
    ];

    // function getFirstFullName(payload) {
    //     if (!Array.isArray(payload) || payload.length === 0) {
    //         return "-";
    //     }
    //     const firstItem = payload[0];
    //     const firstName = firstItem.firstName || '';
    //     const lastName = firstItem.lastName || '';
    //     const fullName = `${firstName} ${lastName}`.trim();
    //     return fullName || "-";
    // }
    function tryParse(val) {
        try {
            let value = JSON.parse(val).value;
            return value;
        } catch {
            return val
        }
    }
    function generateHtmlTable(data) {
        let html = '<table border="1" style="border-collapse: collapse; width: 100%; text-align: left; border-color: black;">';
        html += '<thead>';
        html += '<tr>';
        html += '<th style="padding: 8px;">Name</th>';
        html += '<th style="padding: 8px;">Vote</th>';
        html += '</tr>';
        html += '</thead>';
        html += '<tbody>';
        for (const [name, vote] of Object.entries(data)) {
            html += '<tr>';
            html += `<td style="padding: 8px;">${name}</td>`;
            html += `<td style="padding: 8px;">${vote ? tryParse(vote) : ""}</td>`;
            html += '</tr>';
        }
        html += '</tbody>';
        html += '</table>';
        return html;
    }
    function formatDate(ircDate) {
        // Convert UNIX timestamp to date
        const date = new Date(ircDate * 1000);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        // Determine ordinal suffix
        const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
            (day % 10 === 2 && day !== 12) ? 'nd' :
                (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

        return `${day}<span style={styles.superscript}>${suffix}</span> ${month} ${year}`;
    }

    function formatTime(ircTime) {
        // Parse and format time
        const [hours, minutes] = ircTime.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes);

        const options = { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'Asia/Kathmandu' };
        return date.toLocaleTimeString('en-US', options).replace(/ AM| PM/, match => match + ' NPT');
    }


    function transformData(payload) {
        return payload && payload?.length > 0 && payload.map((item, index) => {
            const rawData = item.data;
            let parsedRole;
            try {
                parsedRole = JSON.parse(rawData[1]);
            } catch (error) {
                parsedRole = rawData[1];
            }

            return {
                sn: (index + 1).toString(),
                name: rawData[0],
                role: parsedRole?.value || "",
                status: rawData[2]
            };
        });
    }
    const replaceHeading = (text) => {
        if (!text) return "";
        const currentStatus = ICReportData?.currentStatus?.[0] || "-"
        const InvestmentName = mattersData?.["deal"] || "-"
        return text.replace("{{currentStatus}}", currentStatus).replace("{{investmentName}}", InvestmentName);
    }
    const ircTime = mattersData?.ircTime ? formatTime(mattersData?.ircTime) : "";
    const ircDate = mattersData?.ircDate ? formatDate(mattersData?.ircDate) : "";
    return (
        <html id="ICDocument">
            <head>
                <style>
                    {`
          html {
            -webkit-print-color-adjust: exact;
          }
        `}
                </style>
            </head>
            <div style={styles.container}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'normal', color: "black" }}>
                    <i>Strictly Private and Confidential</i>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: "20px" }}>
                    <img
                        src={clientLogoURL}
                        alt="client logo"
                        width="160"
                        height="50"
                    />
                </div>
                <div style={styles.titleCenter}>
                    <span >
                        Investment Committee (IC) Meeting Minutes
                    </span>
                </div>
                <h2 style={styles.subjectLeft}>
                    Subject:&nbsp;
                    <span style={styles.plainText}>
                        {replaceHeading(ICReportData?.toolBarConfig?.heading || "")}
                    </span>
                </h2>

                <p style={styles.date}>
                    <b>Date:&nbsp; </b> <span dangerouslySetInnerHTML={{ __html: ircDate }}></span>
                </p>

                <p style={styles.startTimeLeft}>
                    <b>Start Time:&nbsp;</b> <span style={styles.plainText}>{ircTime}</span>
                </p>

                <h3 style={styles.attendeesLeft}>Attendees:</h3>

                {/* Table for attendees */}
                {attendees.length > 0 && <table style={styles.table} border="1" BorderColor="black">
                    <thead>
                        <tr>
                            <th style={{ ...styles.tableHeader, width: "50px" }}>SN</th>
                            <th style={styles.tableHeader}>Name</th>
                            <th style={styles.tableHeader}>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendees.map((attendee, index) => (
                            <tr key={index}>
                                <td style={{ ...styles.tableCellCenter, width: "50px" }}>{attendee?.sn}</td>
                                <td style={styles.tableCell}>{attendee?.name}</td>
                                <td style={styles.tableCell}>{attendee?.role}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}

                {/* Table for matters */}
                <table style={styles.table} border="1" BorderColor="black">
                    <thead>
                        <tr>
                            <th style={styles.tableColoredHeader} colSpan="1">Matters</th>
                            <th style={styles.tableColoredHeader} colSpan="1">Discussions and Decisions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matters.map((matter, index) => (
                            <tr key={index}>
                                <td style={styles.tableCellHead}>{matter?.title}</td>
                                <td style={styles.tableCell}><div dangerouslySetInnerHTML={{ __html: matter?.discussion }}></div></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{ marginTop: "10px", fontWeight: 'normal' }}>
                    Meeting adjourned at {mattersData?.["Meeting Adjourned at"] || "-"}<br />
                    Minutes submitted by {mattersData?.["Meeting Submitted by"] || "-"}<br />
                </div>
            </div>
        </html >
    );
};
// Minutes submitted by {getFirstFullName(owner)}
export default DolmaICReport;
const styles = {
    container: {
        margin: '20px',
        fontFamily: "\"Times New Roman\", serif",
    },
    titleCenter: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '12pt',
        color: "black"
    },
    subjectLeft: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '11pt',
        lineHeight: '111%',
        marginTop: '13pt',
        paddingLeft: '5pt',
        color: "black"
    },
    plainText: {
        fontWeight: 'normal',
    },
    date: {
        textAlign: 'left',
        marginLeft: '5pt',
        marginTop: '9pt',
        fontSize: '11pt',
        color: "black",
        display: "flex"
    },
    superscript: {
        fontSize: '7pt',
        verticalAlign: '3pt',
    },
    startTimeLeft: {
        textAlign: 'left',
        marginTop: '13pt',
        paddingLeft: '5pt',
        fontSize: '11pt',
        fontWeight: 'bold',
        color: "black"
    },
    attendeesLeft: {
        textAlign: 'left',
        marginTop: '13pt',
        paddingLeft: '5pt',
        fontSize: '11pt',
        fontWeight: 'bold',
        color: "black"
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px'
    },
    tableHeader: {
        padding: '4px',
        textAlign: 'center',
        backgroundColor: '#f4f4f4',
    },
    tableColoredHeader: {
        padding: '4px',
        backgroundColor: '#4F81BC',
        color: '#FFF',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    tableCell: {
        padding: '4px',
    },
    tableCellCenter: {
        padding: '4px',
        textAlign: 'center',
    },
    tableCellHead: {
        padding: '4px',
        fontWeight: 'bold',
        verticalAlign: 'top'
    },
};
DolmaICReport.propTypes = {
    ICReportData: PropTypes.any,
};